.broadcast-root {
  h1 {
    margin-bottom: 0;
  }
  button {
    border: 1px solid lightgrey;
  }
  .header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-end;
  }
  .card {
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .event-line {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .searchType-container {
    display: flex;
    gap: 20px;
    &>div {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
  
    }
  }
  .toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    .pages {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 3px;
      .page {
        font-size: 0.8em;
        border-radius: 5px;
        width: 35px;
        background-color: white;
        &.active {
          background-color: #12957D;
          color: white;
        }
      }
    }
  }
  .events {
    border-left: 2px solid lightgrey;
    margin: 10px 0;
    border-radius: 5px;
    display: flex;
    gap: 20px;
    padding: 5px;

    &>div {
      flex-shrink: 0;
    }
    .event-infos {
      flex-grow: 1;
    }
    .event-date {
      width: 170px;
    }
    .event-result-type {
      &>div {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 6px;
      }
  
    }
  }
  .see-more {
    font-size: 0.8em;
  }
}